import React from "react";
import BlogArchive from "../components/BlogArchive";
import Layout from "../components/Layout";
import { graphql } from "gatsby";
import Seo from "src/utils/seo"

export default class BlogArchiveTemplate extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
          <Seo title={'Knowledge Centre'} description={"Here you can find out about all of our news and views!"} /> 
          <BlogArchive posts={this.props.data.posts.edges} numPages={this.props.pageContext.numPages} currentPage={this.props.pageContext.currentPage} />
      </Layout>
    );
  }
}

export const query = graphql`
    query getAllBlogPosts($skip: Int!, $limit: Int!) {
        posts: allStoryblokEntry(
            limit: $limit
            skip: $skip
            filter: {field_component: {eq: "post"}}
            sort: {order: DESC, fields: first_published_at}
        ) {
            edges {
                node {
                    name
                    content
                    full_slug
                    uuid
                    first_published_at
                    created_at
                }
            }
        }
    }
`;
